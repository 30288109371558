import React from "react";
import { Link } from "gatsby";
import toLower from "lodash/toLower";

// components
import Icons from "../Icons/Index";

// data
const searchAPI = require("../../../../public/api/products-temp.json");
// const searchAPI = {};

const queryString = require("query-string");

interface IProps {}

interface IState {
  configuration: {
    sortings: any;
    aggregations: any;
    searchableFields: Array<string>;
  };
  storedSearch: any;
  filters: any;
  query: string;
  itemsjs: any;
  page: number;
  perPage: number;
  sort: string;
}

interface HandleNameChangeInterface {
  target: HTMLInputElement;
}

class ItemsJS extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      storedSearch: "",
      itemsjs: "",
      query: "",
      filters: "",
      page: 1,
      perPage: 24,
      sort: "",
      configuration: {
        searchableFields: [
          "filterFitting",
          "filterSize",
          "filterType",
          "fittingMaterial",
          "productCode",
        ],
        sortings: {
          title_asc: {
            field: "productTitleShort",
            order: "asc",
          },
          title_desc: {
            field: "productTitleShort",
            order: "desc",
          },
          size_asc: {
            field: "filterSize",
            order: "asc",
          },
          size_desc: {
            field: "filterSize",
            order: "desc",
          },
        },
        aggregations: {
          brand: {
            conjunction: true,
            title: "Filter Brand",
            size: 5,
          },
          filterType: {
            conjunction: true,
            title: "Filter Type",
            size: 5,
          },
          filterFitting: {
            conjunction: true,
            title: "Filter Fitting",
            size: 50,
            sort: "term",
            order: "asc",
          },
          filterSize: {
            conjunction: true,
            title: "Filter Size",
            size: 200,
            sort: "term",
            order: "asc",
          },
        },
      },
    };

    const newFilters: Record<string, unknown> = {};
    Object.keys(this.state.configuration.aggregations).map(function (v) {
      newFilters[v] = [];
    });

    // Copying this.state using the spread op (...this.state)
    this.state = {
      ...this.state,
      itemsjs: require("itemsjs")(searchAPI, this.state.configuration),
      // itemsjs: itemsjs(rows, this.state.configuration),
      query: "",
      filters: newFilters,
    };
  }

  componentDidMount(): void {
    const parsed = queryString.parse(location.search);
    if (parsed) this.getParams(parsed);
  }

  changeQuery(e: HandleNameChangeInterface): void {
    this.setState({
      query: e.target.value,
    });
  }

  getParams = (params: Record<string, string>): any => {
    const oldFilters = this.state.filters;
    const newFilters = oldFilters;
    const validTerms = [
      "gas",
      "water",
      "high-temperature",
      "refrigerant",
      ">B< Press",
      ">B< Press XL",
      ">B< MaxiPro",
      ">B< Press Stainless Steel",
    ];
    const validFilters = ["applications", "brand"];

    for (const [key, value] of Object.entries(params)) {
      // Only push valid filter and terms
      if (validTerms.includes(value) && validFilters.includes(key)) {
        newFilters[key].push(value);
      }
    }

    this.setState({
      filters: newFilters,
      page: 1,
    });
  };

  reset = (): void => {
    const newFilters: Record<string, unknown> = {};
    Object.keys(this.state.configuration.aggregations).map(function (v) {
      newFilters[v] = [];
    });
    this.setState({
      filters: newFilters,
      query: "",
    });
  };

  onLastPage = (): boolean => {
    const currentPage = this.searchResult.pagination.page;
    const totalPages = Math.round(
      this.searchResult.pagination.total / this.searchResult.pagination.per_page
    );

    return currentPage == totalPages || totalPages === 0;
  };

  onFirstPage = (): boolean => {
    const currentPage = this.searchResult.pagination.page;
    const totalPages = this.searchResult.pagination.total;

    return currentPage == 1 && totalPages !== 0;
  };

  isNoResults = (): boolean => {
    const totalPages = this.searchResult.pagination.total;

    return totalPages == 0;
  };

  goToNextPage = (): any => {
    const onLastPage = this.onLastPage();

    if (!onLastPage) {
      this.setState((prevState) => {
        return { page: prevState.page + 1 };
      });
    }
  };

  goToPrevPage = (): any => {
    const onFirstPage = this.onFirstPage();

    if (!onFirstPage) {
      this.setState((prevState) => {
        return { page: prevState.page - 1 };
      });
    }
  };

  sortResults = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    const sortValue = event.target.value;
    this.setState({
      sort: sortValue,
    });
  };

  handleCheckbox =
    (filterName: string, filterValue: string) =>
    (event: HandleNameChangeInterface): any => {
      const oldFilters = this.state.filters;
      const newFilters = oldFilters;
      const check = event.target.checked;

      if (check) {
        newFilters[filterName].push(filterValue);

        this.setState({
          filters: newFilters,
          page: 1,
        });
      } else {
        const index = newFilters[filterName].indexOf(filterValue);
        if (index > -1) {
          newFilters[filterName].splice(index, 1);
          this.setState({
            page: 1,
            filters: newFilters,
          });
        }
      }
    };

  get searchResult(): any {
    const result = this.state.itemsjs.search({
      per_page: this.state.perPage,
      page: this.state.page,
      query: this.state.query,
      filters: this.state.filters,
      sort: this.state.sort,
    });
    return result;
  }

  render(): JSX.Element {
    return (
      <div className="c-product-search uk-section uk-section-small">
        <div className="uk-container">
          <div className="uk-flex uk-flex-between uk-flex-middle" uk-grid="">
            <div className="uk-width-auto@m">
              <ul className="uk-breadcrumb">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <span>Products</span>
                </li>
              </ul>
            </div>
            <div className="uk-width-auto@m">
              <span className="c-text-xsmall uk-display-inline-block uk-margin-right uk-margin-small-bottom uk-margin-small-top">
                Showing Products ({this.searchResult.pagination.total})
              </span>
              <div className="uk-display-inline-block uk-width-1-1 uk-width-auto@m">
                <button
                  className="c-product-search__toggle uk-button uk-button-small uk-button-default uk-margin-small-right uk-hidden@m"
                  type="button"
                  uk-toggle="target: #js-product-filter; animation: uk-animation-fade"
                >
                  <span
                    className="uk-margin-small-right"
                    uk-icon="icon: settings; ratio: 0.8"
                  ></span>
                  Filters
                  <span
                    className="c-product-search__toggle-open uk-margin-small-left"
                    uk-icon="icon: chevron-down; ratio: 0.8"
                  >
                    <span className="c-visually__hidden">Open</span>
                  </span>
                  <span
                    className="c-product-search__toggle-close uk-margin-small-left"
                    uk-icon="icon: chevron-up; ratio: 0.8"
                  >
                    <span className="c-visually__hidden">Close</span>
                  </span>
                </button>
                <label
                  htmlFor="sortBy"
                  className="c-text-xsmall uk-display-inline-block uk-margin-right uk-margin-small-bottom uk-margin-small-top"
                >
                  <span className="uk-margin-small-right c-color-grey-300">
                    |
                  </span>
                  Sort by:
                </label>
                <select
                  className="uk-select uk-width-auto"
                  onChange={this.sortResults}
                  disabled={this.isNoResults()}
                  id="sortBy"
                  name="sortBy"
                >
                  <option value="">Most Popular</option>
                  <option value="title_desc">Title &uarr;</option>
                  <option value="title_asc">Title &darr;</option>
                  <option value="size_desc">Fitting Size &uarr;</option>
                  <option value="size_asc">Fitting Size &darr;</option>
                </select>
              </div>
            </div>

            <div className="c-product-search__results" uk-grid="">
              <h2 className="uk-hidden@m">Results</h2>
              <div className="uk-width-1-1 uk-width-3-4@m" uk-margin="">
                {this.searchResult.data.items.length ? (
                  <ul
                    className="uk-grid-match uk-grid-small uk-child-width-1-1 uk-child-width-1-2@s uk-child-width-1-3@l"
                    uk-grid=""
                  >
                    {Object.entries(this.searchResult.data.items).map(
                      ([key, item]: any) => {
                        const productApplication: any = toLower(
                          item.filterType
                        );
                        return (
                          <li
                            key={`product_item_${key}`}
                            data-name={item.productTitleShort}
                            data-brand={item.brandSlug}
                            data-application={productApplication}
                          >
                            <Link
                              className="c-product-search__card uk-card uk-card-small uk-link-toggle uk-flex uk-flex-column"
                              to={`/products/${item.productSlug}`}
                            >
                              <div
                                className={`uk-card-header c-background-${
                                  productApplication || "misc"
                                } uk-light`}
                              >
                                <h1 className="uk-card-title uk-text-uppercase">
                                  {item.productTitleShort}
                                </h1>
                              </div>
                              <div
                                className="uk-card-body uk-text-emphasis uk-flex uk-flex-column uk-animation-toggle"
                                tabIndex={0}
                                uk-margin=""
                              >
                                <div className="c-text-xsmall">
                                  {item.brand}
                                </div>

                                <div className="uk-padding uk-animation-kenburns uk-animation-fast">
                                  <div className="uk-cover-container uk-background-muted">
                                    <canvas width="280" height="210"></canvas>
                                    {item.productImage && (
                                      <img
                                        src={`/products/details/${item.productImage}.jpg`}
                                        alt={item.fields?.productTitleLong}
                                        className="uk-margin-auto uk-display-block"
                                        width=""
                                        height=""
                                        uk-cover=""
                                        onError={({ currentTarget }) => {
                                          currentTarget.onerror = null; // prevents looping
                                          currentTarget.src = "/load-error.jpg";
                                          currentTarget.alt =
                                            "Image couldn't load";
                                        }}
                                      />
                                    )}
                                  </div>
                                </div>

                                <div className="c-product-search__icons">
                                  {productApplication && (
                                    <Icons
                                      iconType={productApplication}
                                      iconSize="xsmall"
                                    />
                                  )}
                                </div>
                              </div>
                            </Link>
                          </li>
                        );
                      }
                    )}
                  </ul>
                ) : (
                  <div className="uk-text-center">
                    There are currently no results for the selected filters
                    <br />
                    <button
                      className="uk-margin uk-button uk-button-primary"
                      onClick={this.reset}
                    >
                      Reset
                    </button>
                  </div>
                )}
                {!this.isNoResults() && (
                  <div className="c-noprint uk-margin-large-top uk-clearfix">
                    <div className="uk-float-left">
                      <button
                        className="uk-button uk-button-primary"
                        onClick={() => this.goToPrevPage()}
                        disabled={this.onFirstPage() || this.isNoResults()}
                      >
                        Prev
                      </button>
                    </div>
                    <div className="uk-float-right">
                      <button
                        className="uk-button uk-button-primary"
                        onClick={() => this.goToNextPage()}
                        disabled={this.onLastPage() || this.isNoResults()}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                )}
              </div>
              <div
                className="c-product-search__filters uk-width-1-1 uk-width-1-4@m uk-flex-first"
                id="js-product-filter"
              >
                <aside>
                  <h2 className="uk-hidden@m">Filters</h2>
                  <hr className="uk-divider-small uk-hidden@m" />
                  <ul
                    className="uk-nav-default uk-nav-parent-icon"
                    uk-nav="multiple: true;"
                  >
                    {Object.entries(this.searchResult.data.aggregations).map(
                      ([key, value]: any, index: number) => {
                        return (
                          <li
                            key={`filter_parent_${key}`}
                            className={`${
                              index < 2 ? "uk-open" : ""
                            } uk-parent`}
                          >
                            <a href="#">{value.title}</a>
                            <ul className="uk-list-reset">
                              {Object.entries(value.buckets).map(
                                ([keyB, valueB]: any) => {
                                  const valueBkeyClean = valueB.key.replace(
                                    /[^0-9a-z]/gi,
                                    ""
                                  );

                                  return (
                                    <li key={`filter_${keyB}`}>
                                      <div
                                        className={`c-product-search__controls ${
                                          valueB.doc_count === 0
                                            ? "c-product-search__controls-disabled"
                                            : ""
                                        }`}
                                      >
                                        <input
                                          name={value.title}
                                          id={valueBkeyClean}
                                          value={valueB.key}
                                          type="checkbox"
                                          checked={
                                            this.state.filters[
                                              value.name
                                            ].indexOf(valueB.key) > -1 || false
                                          }
                                          onChange={this.handleCheckbox(
                                            value.name,
                                            valueB.key
                                          )}
                                        />
                                        <label
                                          className="uk-width-1-1 c-text-xsmall"
                                          htmlFor={valueBkeyClean}
                                        >
                                          {value.title.includes("Type") ? (
                                            <Icons
                                              iconType={valueB.key.toLowerCase()}
                                              iconSize="xsmall"
                                              customTitle={`${valueB.key.replace(
                                                /-/g,
                                                " "
                                              )} (${valueB.doc_count})`}
                                            />
                                          ) : (
                                            `${valueB.key} (${valueB.doc_count})`
                                          )}
                                        </label>
                                      </div>
                                    </li>
                                  );
                                }
                              )}
                            </ul>
                          </li>
                        );
                      }
                    )}
                  </ul>
                </aside>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ItemsJS;
