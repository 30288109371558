import React from "react";

interface Props {
  title: string | null;
  summary?: string | null;
}

const BannerBasic: React.FC<Props> = (props) => {
  const { title, summary } = props;

  return (
    <section className="uk-section uk-section-small uk-background-muted">
      <div className="uk-container uk-margin-auto">
        <div className="uk-margin-auto uk-width-1-1 uk-width-3-4@m uk-width-3-4@l uk-width-1-1@xl">
          {title && (
            <h1
              className="uk-h4 uk-h2@l uk-h1@xl uk-margin-small-top uk-margin-small-bottom"
              dangerouslySetInnerHTML={{ __html: title }}
              uk-slider-parallax="x: 200,-200"
            />
          )}
          {summary && (
            <h2
              className="uk-h2 uk-h3@m uk-h2@l uk-h1@xl uk-margin-medium-top uk-margin-small-bottom"
              dangerouslySetInnerHTML={{ __html: summary }}
              uk-slider-parallax="x: 200,-200"
            />
          )}
          <hr className="uk-divider-small uk-margin-small-bottom" />
        </div>
      </div>
    </section>
  );
};

export default BannerBasic;
