import * as React from "react";

// Components
import Layout from "../components/Layouts/Layouts";
import Seo from "../components/Layouts/Seo";
import ProductSearch from "../components/Content/Product/Search";
import BannerBasic from "../components/Content/Banner/Basic";

// markup
const IndexPage: React.FC = () => {
  return (
    <Layout>
      <Seo
        title="Products"
        description="Conex Bänninger products are available through Reece stores. They’re available near you."
      />
      <BannerBasic title="Products" />
      <ProductSearch />
    </Layout>
  );
};

export default IndexPage;
